<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items"
  :querySearch="getData"
  isHiddenTableCount
  isHiddenPageCount).px-4
    Form(slot="AddBlock")

</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'BackOfficeCoefficient',
  components: {
    Form: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'date_start', text: this.$t('dateEffective') },
        { value: 'date_end', text: this.$t('dateEnd') },
        { value: 'percent_of_eti', text: this.$t('etiPercent') },
        { value: 'percent_of_profit', text: this.$t('profitPercent') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: 'coefficients-backoffice-info', params: { ...this.$route.params, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.coefficient,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getListCoefficient']),
    async getData (params) {
      this.getListCoefficient({ ...this.$route.params, params })
    }

  }
}
</script>
